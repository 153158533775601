import { Injectable } from '@angular/core';
import { WindowRef } from '@vanguard/secure-site-components-lib';

/**
 * Helper service to navigate outside application
 */
@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private windowRef: WindowRef) {}

  /**
   * Navigates outside app
   */
  public navigate(url: string): void {
    this.windowRef.setHref(url);
  }
}
