import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { 
  HistoricalDocumentsResponse, 
  HistoricalDocumentTypeResponse } from "models/historical-documents.model";

const httpOptions = {
  withCredentials: true,
};

@Injectable({
  providedIn: 'root'
})


export class HistoricalDocumentsService {
  constructor(
    private readonly http: HttpClient
  ) { }

  getHistoricalDocuments(): Observable<HistoricalDocumentsResponse> {
    // httpOptions['params'] = data;
    httpOptions['headers'] = {
      'urlFlag': 'getHistoricalDocuments'
    };
    const endpoint = `${environment.HISTORICAL_DOCUMENTS.BASE_URL}${environment.HISTORICAL_DOCUMENTS.CONSUMER_URL}`;
    return this.http.get<HistoricalDocumentsResponse>(endpoint, httpOptions);
    // return of({historicalDocuments:[]})
    // return of( {
    //   historicalDocuments: [
    //     {
    //       historicalDocumentId: 'VualIGmuLPS3Vpys83iwSUQqO3u4QfgQa/JXruzdYrBAAndEsyx7gGJYF9djwt3YBywW',
    //       historicalDocumentTypeCode: 'MTRNS',
    //       historicalDocumentDescription: 'Cajan Cabal—SEP IRA Brokerage—85774079',
    //       requestDate: '2023-03-29',
    //       creationDate: '2002-07-29',
    //     },
    //     {
    //       historicalDocumentId: 'VualIGmuLPS3Vpys83iwSUQqO3u4QfgQa/JXruzdYrBAAndEsyx7gGJYF9djwt3YBywW',
    //       historicalDocumentTypeCode: 'MTRNS',
    //       historicalDocumentDescription: 'Cbajan Cabal—SEP IRA Brokerage—85774079',
    //       requestDate: '2022-12-20',
    //       creationDate: '2012-03-29',
    //     },
    //     {
    //       historicalDocumentId: 'VualIGmuLPS3Vpys83iwSUQqO3u4QfgQa/JXruzdYrBAAndEsyx7gGJYF9djwt3YBywW',
    //       historicalDocumentTypeCode: 'MTRNS',
    //       historicalDocumentDescription: 'Ccajan Cabal—SEP IRA Brokerage—85774079',
    //       requestDate: '2015-01-14',
    //       creationDate: '2011-10-29',
    //     },
    //   ],
    // });
  }

  getHistoricalDocTypes(): Observable<HistoricalDocumentTypeResponse> {
    const endpoint = `${environment.HISTORICAL_DOCUMENTS.BASE_URL}${environment.HISTORICAL_DOCUMENTS.HISTORICAL_DOCUMENT_TYPES}`;
    httpOptions['params'] = null;
    httpOptions['headers'] = {
      'urlFlag': 'getHistoricalDocumentsTypes'
    };
    // return of();
    // return of(
    //   {
    //     "types": [
    //         {
    //           "historicalDocumentTypeCode": "ADMD",
    //           "abbreviatedName": "Dialogue Ltr",
    //           "name": "Various activity for Dialogue Ltrs",
    //           "description": "Notification"
    //         },
    //       {
    //         "historicalDocumentTypeCode": "ADMDa",
    //         "abbreviatedName": "Dialogue Ltr",
    //         "name": "Various activity for Dialogue Ltrs",
    //         "description": "Notificationa"
    //       },
    //       {
    //         "historicalDocumentTypeCode": "ADMDb",
    //         "abbreviatedName": "Dialogue Ltr",
    //         "name": "Various activity for Dialogue Ltrs",
    //         "description": "Notificationb"
    //       }
    //     ]
    //   }
    // );
    return this.http.get<HistoricalDocumentTypeResponse>(endpoint, httpOptions);
  }

  downloadPdf(docId: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('historicalDocumentId', docId);
    headers = headers.set('urlFlag', 'getPdf');
    const endpoint = `${environment.HISTORICAL_DOCUMENTS.BASE_URL}${environment.HISTORICAL_DOCUMENTS.PDF_URL}`;
    return this.http.get(endpoint, { headers, responseType: 'arraybuffer', observe: 'response' });
  }
}
