import { Component, OnInit, ViewChild } from '@angular/core';
import { gql } from 'apollo-angular';
import moment from 'moment';
import { HistoricalDocumentsContent, TableHeaderObject } from '../../models/caas.model';
import { ModalDialogComponent } from '@vg-constellation/angular-13/modal-dialog';
import { CaasService } from '../../services/caas/caas.service';
import { LinkOption } from '../tetriary-navigation/link-option.model';
import { HistoricalDocumentsService } from 'services/historical-documents/historical-documents.service';
import {
  HistoricalDocListObject,
  HistoricalDocumentsResponse,
  HistoricalDocumentType,
  HistoricalDocumentTypeResponse
} from "models/historical-documents.model";
import { HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-historical-documents',
  templateUrl: './historical-documents.component.html',
  styleUrls: ['./historical-documents.component.scss']
})

export class HistoricalDocumentsComponent implements OnInit {
  errorBannerConfig = {
    size: 'medium',
    heading: '',
    body: '',
    closable: false,
    collapsible: false,
    type: 'error',
  };
  hasCloseControl = false;
  hasDetails = false;
  public tertiaryNavigationOptions: LinkOption[];
  tableheading: string = 'Document date'
  query = gql`
    query {
      client {
        preferredName
        lastLoginTimestamp
      }
      accountsInfo {
        totals {
          totalBalance
          totalBalanceAsOfDate
        }
      }
      unreadSecureMessageCount
    }
  `;
  showOptOutButton = false;
  historicalDocumentsContent: HistoricalDocumentsContent;
  // viewDropdownContent: any = [];
  // dropdownMenuOptions: DropdownMenuData;
  contentBody: string;
  shouldShowErrorBanner = false;
  public readonly tertiaryNavigationActiveItem: string = 'Historical documents';
  showDocumentDate: boolean = true;
  showDocumentType: boolean = false;
  showDescription: boolean = false;
  showDownload: boolean = false;
  public isLoaderIndicator = true;
  historicalDocumentsList: HistoricalDocListObject[] = [];
  historicalDocTypesList: HistoricalDocumentType[];
  tableSectionHeader: TableHeaderObject = {
    requestDateColumnLabel: '',
    documentDateColumnLabel: '',
    documentTypeColumnLabel: '',
    descriptionColumnLabel: '',
    viewDownloadColumnLabel: ''
  }

  requestDateSortingState = 'descending';
  creationDateSortingState = 'none';
  historicalDocumentTypeSortingState = 'none';
  descriptionSortingState = 'none';
  previousSortedColumn = 'requestDateSortingState';
  docContentType = '';
  fileExtn = '';

  downloadLinkLabel = environment.downloadAccountLink.label
  downloadLinkUrl = environment.downloadAccountLink.url

  @ViewChild(ModalDialogComponent) modal: ModalDialogComponent;
  @ViewChild('errorModal') errorModal: ModalDialogComponent;
  errorPdfDownloadHeader: string;
  errorData: { [p: string]: string };
  shouldShowErrorBannerForTable: boolean = false;
  defaultErrorCode: string = "DEFAULT_ERROR";
  constructor(
    private readonly caasService: CaasService,
    private readonly historicalDocumentsService: HistoricalDocumentsService,
  ) { }

  async ngOnInit() {
    this.getCaasData();
    // this.dropdownMenuOptions = { groups: [{ items: this.viewDropdownContent }] };
    this.getHistoricalDocTypesList();
  }

  getCaasData() {
    const response = this.caasService.getCaasData();
    if (response) {
      this.historicalDocumentsContent = response.Data['historical-document']
        ? response.Data['historical-document'].content : {
          browserTitle: '',
          pageTitle: '',
          dropdownOptions: { 'stmts-dropdown-option01': { 'optionLabel': '', 'optionUrl': '' } },
          tabOptions: { 'tab-option01': { 'optionLabel': '', 'optionUrl': '' } }
        };
    }

    this.errorBannerConfig.heading = '';
    this.errorBannerConfig.body = '';
    // this.contentBody = 'No documents are currently in the 90 day requested period.'
    // this.apiFailureErrorBody = 'The information is currently unavailable. Please try again later.';
    this.errorPdfDownloadHeader = 'The information is currently unavailable';
    this.tertiaryNavigationOptions = Object.keys(this.historicalDocumentsContent.tabOptions).sort().map(key =>
    ({
      'name': this.historicalDocumentsContent.tabOptions[key].optionLabel,
      'url': this.historicalDocumentsContent.tabOptions[key].optionUrl,
      'key': this.historicalDocumentsContent.tabOptions[key].optionLabel
    })
    );
    const tempHeaderArr = Object.keys(this.historicalDocumentsContent.tableSection).sort().map(key =>
    ({
      'value': this.historicalDocumentsContent.tableSection[key].optionValue,
      'key': this.historicalDocumentsContent.tableSection[key].optionKey
    }))
    const tempErrorArr = Object.keys(this.historicalDocumentsContent.errorObject).sort().map(key =>
      ({
        'value': this.historicalDocumentsContent.errorObject[key].optionValue,
        'key': this.historicalDocumentsContent.errorObject[key].optionKey
      })
    );
    // console.log(Object.assign({}, ...(tempHeaderArr.map(item => ({ [item.key]: item.value })))))
    this.tableSectionHeader = Object.assign({}, ...(tempHeaderArr.map(item => ({ [item.key]: item.value }))));
    this.errorData = Object.assign({}, ...(tempErrorArr.map(item => ({ [item.key]: item.value }))));
    // console.log(this.errorData);
  }

  async getHistoricalDocTypesList() {
    try {
      this.isLoaderIndicator = true;
      this.shouldShowErrorBannerForTable = false;
      const historicalDocTypesArray = await this.getHistoricalDocTypes();
      this.historicalDocTypesList = historicalDocTypesArray.types;
      await this.getHistoricalDocumentsList();
    }
    catch (err: any) {
      console.log('getHistoricalDocTypesList catch block ->', err);
      const errorCode = err.error.code;
      this.isLoaderIndicator = false;
      this.shouldShowErrorBannerForTable = true;
      this.contentBody = this.errorData[errorCode] || this.errorData[this.defaultErrorCode] || err.error.message
    }
  }

  async getHistoricalDocumentsList() {
    try {
      const newHistoricalDocumentsList = await this.getHistoricalDocuments();
      this.historicalDocumentsList = newHistoricalDocumentsList.historicalDocuments;

      this.historicalDocumentsList.forEach(hdObj => {
        const tempObj = this.historicalDocTypesList.find(hdTypeObj => hdTypeObj.type === hdObj.type)
        hdObj.historicalDocumentType = tempObj?.name || '';
        hdObj.requestDate = moment(hdObj.requestDate).format('MM/DD/YYYY');
        hdObj.creationDate = moment(hdObj.creationDate).format('MM/DD/YYYY');
      });

      if (this.historicalDocumentsList.length === 0) {
        this.shouldShowErrorBannerForTable= true;
        this.contentBody = this.errorData['ERROR_003']
      }

      // sort by request date
      this.historicalDocumentsList.sort((a,b) =>
      moment(a.requestDate) > moment(b.requestDate) ? -1 : moment(a.requestDate) < moment(b.requestDate) ? 1 : 0
      );
      this.requestDateSortingState = 'descending';

      // sort by creation date where request dates match
      this.historicalDocumentsList.sort((a, b) =>
      a.requestDate === b.requestDate ? ( moment(a.creationDate) > moment(b.creationDate) ? -1 : moment(a.creationDate) < moment(b.creationDate) ? 1 : 0 ) : 0
      );

      // sort by document type where request dates & creation date match
      this.historicalDocumentsList.sort((a, b) =>
        (a.requestDate === b.requestDate && a.creationDate === b.creationDate &&
          a.historicalDocumentType && b.historicalDocumentType) ?
        a.historicalDocumentType.localeCompare(b.historicalDocumentType) : 0
      );

      // sort by description where request dates & creation date & HD type match
      this.historicalDocumentsList.sort((a, b) =>
        (a.requestDate === b.requestDate && a.creationDate === b.creationDate &&
          a.historicalDocumentType === b.historicalDocumentType) ?
        a.description.localeCompare(b.description) : 0
      );

      this.isLoaderIndicator = false;
    } catch (err: any) {
      const errorCode = err.error.code;
      this.shouldShowErrorBannerForTable = true;
      this.contentBody = this.errorData[errorCode] || this.errorData[this.defaultErrorCode] || err.error.message
      this.isLoaderIndicator = false;
    }
  }

  private getHistoricalDocTypes(): Promise<HistoricalDocumentTypeResponse> {
    return this.historicalDocumentsService.getHistoricalDocTypes().toPromise();
  }

  private getHistoricalDocuments(): Promise<HistoricalDocumentsResponse> {
    return this.historicalDocumentsService.getHistoricalDocuments().toPromise();
  }

  toggleAccountdownload(value: string) {
    this.tableheading = value;
    if (value === this.tableSectionHeader.documentDateColumnLabel) {
      this.showDocumentType = false;
      this.showDownload = false;
      this.showDescription = false;
      this.showDocumentDate = true;
    } else if (value === this.tableSectionHeader.documentTypeColumnLabel) {
      this.showDocumentType = true;
      this.showDownload = false;
      this.showDescription = false;
      this.showDocumentDate = false
    } else if (value === this.tableSectionHeader.descriptionColumnLabel) {
      this.showDocumentType = false;
      this.showDownload = false;
      this.showDescription = true;
      this.showDocumentDate = false
    } else if (value === this.tableSectionHeader.viewDownloadColumnLabel) {
      this.showDocumentType = false;
      this.showDownload = true;
      this.showDescription = false;
      this.showDocumentDate = false
    }
    this.modal.closeDialogModal();
  }

  downloadpdf(docId: string, event, docType, docDate) {
    this.isLoaderIndicator = true;
    docDate = moment(docDate).format('MM-DD-YYYY');
    const fileName = docType + '_' + docDate + '.';

    this.historicalDocumentsService.downloadPdf(docId).subscribe((data: HttpResponse<any>) => {
      this.isLoaderIndicator = false;
      this.docContentType = data.headers.get('Content-Type') || '';

      switch (this.docContentType) {
        case 'application/pdf':
          this.fileExtn = 'pdf';
          break;
        case 'image/jpeg':
          this.fileExtn = 'jpeg';
          break;
        case 'image/png':
          this.fileExtn = 'png';
          break;
        case 'image/tiff':
          this.fileExtn = 'tiff';
          break;
        case 'text/html; charset=UTF-8':
          this.fileExtn = 'html';
          break;
        case 'text/html':
          this.fileExtn = 'html'
          break;
      }

      this.downloadFile(data, fileName + this.fileExtn);
    }, (error) => {
      const parsedJson = JSON.parse(new TextDecoder().decode(error.error as ArrayBuffer));
      const errorCode = parsedJson.code;
      console.log(JSON.parse(new TextDecoder().decode(error.error as ArrayBuffer)));
      console.log(error)
      this.shouldShowErrorBannerForTable = false;
      this.contentBody = this.errorData[errorCode] || this.errorData[this.defaultErrorCode] || parsedJson.message
      this.errorModal.openModalDialog(event);
      this.isLoaderIndicator = false;
    })
  }

  downloadFile(response, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }

  keyHandler(event, link, docType, docDate) {
    event.stopPropagation();
    this.downloadpdf(link, event, docType, docDate);
  }

  sortColumn( columnName, sortType ) {
    const masterList = this.historicalDocumentsList;
    const columnSortingState = columnName + 'SortingState';

    this.previousSortedColumn != columnSortingState ? this[this.previousSortedColumn] = 'none' : null;

    if (this[columnSortingState] === 'descending') {
      this[columnSortingState] = 'ascending';
      sortType === 'date' ? masterList.sort((a, b) => moment(a[columnName]) < moment(b[columnName]) ? -1 : moment(a[columnName]) > moment(b[columnName]) ? 1 : 0) :
        masterList.sort( (a, b) => a[columnName].localeCompare( b[columnName] ) );
      this.previousSortedColumn = columnSortingState;
      this.historicalDocumentsList = [...masterList];
    } else {
      this[columnSortingState] = 'descending';
      sortType === 'date' ? masterList.sort((a, b) => moment(a[columnName]) > moment(b[columnName]) ? -1 : moment(a[columnName]) < moment(b[columnName]) ? 1 : 0) :
        masterList.sort( (a, b) => b[columnName].localeCompare( a[columnName] ) );
      this.previousSortedColumn = columnSortingState;
      this.historicalDocumentsList = [...masterList];
    }
  }
}
