import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DropdownMenuData, DropdownMenuItem, DropdownState } from '@vg-constellation/angular-13/dropdown';
import { NavigationService } from 'app/services/navigation/navigation.service';
// import { content } from 'content/content';

import { LinkOption } from './link-option.model';

export const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
); // fragment locator

export const vgUrlPattern = /\.vanguard\.com(?::{0,1}\d{0,5})\//;

@Component({
  selector: 'app-tetriary-navigation',
  templateUrl: './tetriary-navigation.component.html',
  styleUrls: ['./tetriary-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TetriaryNavigationComponent implements OnChanges {
  // private content = content;
  public dropdownOptions: DropdownMenuData;
  public titleText: string;
  /**
   * Link options to display
   */
  public options: LinkOption[] = [];

  @Input()
  public linkOptions: LinkOption[] = [];

  @Input()
  public activeLink: string;

  constructor(private readonly navigationService: NavigationService) {
    this.titleText = "Show:";
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.linkOptions && this.linkOptions) {
      this.options = this.linkOptions.filter(option => this.isValidUrl(option, urlPattern, vgUrlPattern));
      this.dropdownOptions = this.getDropdownOptions(this.linkOptions);
    }
  }

  public dropdownSelected($event: DropdownState): void {
    const urlKey = $event.activeItems[0];
    const option = this.options.find(option => option.key === urlKey);

    if (option && option.url) {
      this.navigationService.navigate(option.url);
    }
  }

  private getDropdownOptions(options: LinkOption[]): DropdownMenuData {
    const itemList = options.reduce((itemsCollector: DropdownMenuItem[], option: LinkOption) => {
      return [...itemsCollector, { id: option.key, labelText: option.name }];
    }, []);
    return { groups: [{ items: itemList }] };
  }

  private isValidUrl(option: LinkOption, urlPattern, vgUrlPattern): boolean {
    const url = option.url;
    return !url || (urlPattern.test(url) && vgUrlPattern.test(url));
  }
}
