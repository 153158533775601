import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HistoricalDocObject } from '../../models/caas.model';
import {LoggerService} from "@vanguard/secure-site-components-lib";
import PKG from '../../../../package.json';
@Injectable({
  providedIn: 'root'
})
export class CaasService {
  private caasData: HistoricalDocObject;
  private readonly logger:LoggerService
  constructor( private readonly http: HttpClient) {}

  getContent() {
    return this.http.get<HistoricalDocObject>(`${environment.HISTORICAL_DOCUMENTS.BASE_URL}${environment.HISTORICAL_DOCUMENTS.CAAS_URL}`).pipe().toPromise().then((response) => {
      this.caasData = response;
    }).catch((err) => {
      this.logger.error({
        message: err?.message,
        logCode: PKG.deployment.appPrefix
      })
      document.location.href = environment.LOGON_URL;
    });
  }
  
  getCaasData(){
    return this.caasData;
  }
}