import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {
  WindowRef,
  ENVIRONMENT_CONFIG as COMPONENT_LIB_ENVIRONMENT_CONFIG,
  Environment as ComponentLibEnvironment,
  PageLayoutModule,
  SecondaryNavigationModule,
  WindowRefModule
} from '@vanguard/secure-site-components-lib';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './auth.interceptor';
import { AppComponent } from './app.component';
import { LinkModule } from '@vg-constellation/angular-13/link';
import { IconModule } from '@vg-constellation/angular-13/icon';
import { ButtonModule } from '@vg-constellation/angular-13/button';
import { SpinnerModule } from '@vg-constellation/angular-13/spinner';
import { DropdownModule } from '@vg-constellation/angular-13/dropdown';
import { ModalDialogComponent, ModalDialogModule } from '@vg-constellation/angular-13/modal-dialog';
import { HistoricalDocumentsComponent } from "app/components/historical-documents/historical-documents.component";
import { HistoricalDocumentsService } from 'services/historical-documents/historical-documents.service';
import { CaasService } from './services/caas/caas.service';
import { environment } from "environments/environment";
import { LoggerModule, LOGGER_CONFIG } from "@vanguard/secure-site-components-lib";
import PKG from 'package.json';
import otlAngularConfig from '@vanguard/otl-angular-wrapper'
import {
  OpenTelemetryInterceptorModule,
  OtelColExporterModule,
  W3CTraceContextPropagatorModule
} from '@jufab/opentelemetry-angular-interceptor'

import { GatekeeperService } from "services/gatekeeper/gatekeeper.service";
import { LoggerService } from "@vanguard/secure-site-components-lib";
import { TetriaryNavigationComponent } from './components/tetriary-navigation/tetriary-navigation.component';
import {BannerModule} from "@vg-constellation/angular-13/banner";

@NgModule({
  declarations: [AppComponent, HistoricalDocumentsComponent, TetriaryNavigationComponent],
  imports: [BrowserModule, AppRoutingModule, LinkModule, IconModule, HttpClientModule, SecondaryNavigationModule, PageLayoutModule,
    LoggerModule,
    ModalDialogModule,
    DropdownModule,
    ButtonModule,
    SpinnerModule,
    WindowRefModule,
    OpenTelemetryInterceptorModule.forRoot(
      otlAngularConfig({
        serviceName: PKG.deployment.appPrefix,
        appPrefix: PKG.deployment.appPrefix,
        sysLevel: environment.deployment
      })
    ),
    OtelColExporterModule,
    W3CTraceContextPropagatorModule, BannerModule
  ],
  providers: [ModalDialogComponent,
    HistoricalDocumentsService,
    CaasService,
    {
      provide: LOGGER_CONFIG,
      useValue: { endpoint: `${environment.HISTORICAL_DOCUMENTS.CONSUMER_URL}${environment.HISTORICAL_DOCUMENTS.LOGGER}`, app: PKG.deployment.appPrefix }
    },
    {
      provide: COMPONENT_LIB_ENVIRONMENT_CONFIG,
      useValue: {
        isProd: environment.production, // a boolean indicating whether library is being used in a production environment
        appName: PKG.name, // a string identifying the app hosting the component library.
        isPerf: environment.performance,
        isInternal: environment.internal
      } as ComponentLibEnvironment,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory(http: HttpClient, meta: Meta, windowRef: WindowRef) {
        const $window: Window = windowRef.nativeWindow;
        return async () => {
          await http
            .get(`${environment.HISTORICAL_DOCUMENTS.BASE_URL}/adobe`, {withCredentials: true})
            .toPromise()
            .then((data: any) => {
              const spoid = data.sPOID;
              meta.updateTag({name: 'DCSext.poid', content: data.poid});
              meta.updateTag({name: 'DCSext.sPoid', content: spoid}); // Used for Floodlight
              meta.updateTag({name: 'WT.seg_6', content: data.crewFlag});
              $window.spoid = spoid; // Used for Medallia
              $window.document.body.hidden = false;
            })
            .catch(() => {
              $window.document.location.href = environment.LOGON_URL;
              // Ensure that app initialization is aborted since we're going to the (external) logon page.
              return Promise.reject();
            });
        };
      },
      deps: [HttpClient, Meta, WindowRef],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (caasService: CaasService) =>
        () => caasService.getContent(),
      deps: [CaasService],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory(gatekeeperService: GatekeeperService, windowRef: WindowRef, loggerService: LoggerService) {
        const $window: Window = windowRef.nativeWindow;
        return (): Promise<any> => {
          return new Promise((resolve) => {
            gatekeeperService.isFeatureSwitchedOn(environment.GATEKEEPER_FEATURE_ID).toPromise()
              .then((response: boolean) => {
                if (response) {
                  // redirect to legacy if gatekeeper is set to true
                  $window.document.location.href = environment.LEGACY_URL + '?redirect=true';
                } else {
                  gatekeeperService.redirectToModernizedApp();
                }
                resolve(true)
              }).catch((err) => {
                loggerService.info({ app: PKG.deployment.appPrefix, message: err, logCode: '403' });
                resolve(true);
              })
          });
        }


      },
      deps: [GatekeeperService, WindowRef],
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
