<div class="d-none d-lg-block" data-testid="links">
  <div role="navigation" aria-label="Tertiary navigation" class="tetriary-navigation-list__container">
    <ng-container *ngFor="let option of options">
      <div *ngIf="option.key === activeLink; else nonActiveLink">
        <a data-testid="active-link" c11n-link variant="secondary-reinforced" fontWeight="bold" [href]="option.url"
          [attr.aria-current]="true" class="tetriary-navigation-item__active">
          <span class="c11n-link__text">
            {{ option.name }}
          </span>
        </a>
      </div>
      <ng-template #nonActiveLink>
        <div>
          <a c11n-link variant="secondary-independent" [href]="option.url" fontWeight="normal"
            data-testid="nonactive-link">
            <span class="c11n-link__text">
              {{ option.name }}
            </span>
          </a>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<div class="d-block d-lg-none">
  <div class="tetriary-navigation-dropdown__container">
    <div class="tetriary-navigation__title mt-1 c11n-text-md">
      <label for="tetriary-navigation-dropdown_id" class="tetriary-navigation__title-text">{{ titleText }}</label>
    </div>
    <div class="tetriary-navigation-dropdown__content">
      <c11n-dropdown *ngIf="dropdownOptions" id="tetriary-navigation-dropdown_id"
        (stateChange)="dropdownSelected($event)" [labelText]="''" [menuData]="dropdownOptions"
        [activeItems]="activeLink">
      </c11n-dropdown>
    </div>
  </div>
</div>