<title class="greeting_info_a11y_page_header" tabindex="-1">
  {{historicalDocumentsContent.pageTitle}}
</title>
<ss-lib-page-layout>
  <div inner>
    <div class="row">
      <div class="col">
        <secondary-nav-widget active-item="ActivityTab" ></secondary-nav-widget>
        <div class="tertiary-nav" data-testid="tertiary-nav">
          <div class="tertiary-nav__customize_account">
            <a c11n-link [attr.href]="downloadLinkUrl" data-testid="download-center-link" variant="secondary-independent"
              fontWeight="normal" [attr.aria-label]="'Download center account information'">
              <span class="c11n-link__text">{{ downloadLinkLabel }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-tetriary-navigation [linkOptions]="tertiaryNavigationOptions" [activeLink]="tertiaryNavigationActiveItem">
        </app-tetriary-navigation>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <c11n-banner *ngIf="shouldShowErrorBanner" [size]="'medium'" [headingText]="errorBannerConfig.heading"
          [hasCloseControl]="errorBannerConfig.closable" [variant]="'error'">
          <div class="c11n-text-md--crop">{{errorBannerConfig.body}}</div>
        </c11n-banner>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="transaction-summary">
          <div id="historical-documents-summary-section">
            <div class="main-div">
              <!-- <c11n-spinner size="medium" colorMode="on-light" spinnerStatus="Loading..."></c11n-spinner> -->

              <div class="line-copy"></div>
              <div class="container-fluid p-0">
                <div class="row">
                  <div class="col-md-12 c11n-grid-story__cell">

                    <c11n-spinner *ngIf="isLoaderIndicator" class="spinner-on-screen" size="medium" colorMode="on-light"
                      spinnerStatus="Loading...">
                    </c11n-spinner>
                    <table id="historical-document-table"
                      class="mt-3 c11n-table c11n-table--small c11n-table--sticky-th-horizontal c11n-table--sticky-th-vertical"
                      data-cy="recent-trans-table" data-vui-sticky-elements-parent="">

                      <thead>
                        <tr class="c11n-table__tr">
                          <th scope="col" class="c11n-table__thead-th" [attr.aria-sort]="requestDateSortingState"
                            [ngClass]="[requestDateSortingState, requestDateSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']">
                            <button type="button" (click)="sortColumn('requestDate', 'date')"
                              [attr.aria-label]="'Sorted ' + requestDateSortingState"
                              class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                              title="Sort by request date" aria-label="sort by request date" style="width: 100%;">
                              <span class="c11n-link__content">
                                <span id="requestDateId"
                                  class="c11n-link__text type-header">{{tableSectionHeader.requestDateColumnLabel}}</span>
                                <svg viewBox="0 0 8 14" focusable="false" aria-hidden="true">
                                  <use xlink:href="#c11n-table-sort-arrow"></use>
                                  <use xlink:href="#c11n-table-sort-arrow"></use>
                                </svg>
                              </span>
                            </button>
                          </th>

                          <th scope="col" class="c11n-table__thead-th" [attr.aria-sort]="creationDateSortingState"
                            [ngClass]="[creationDateSortingState, creationDateSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']">
                            <button type="button" (click)="sortColumn('creationDate', 'date')"
                              [attr.aria-label]="'Sorted ' + creationDateSortingState"
                              class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal account-header"
                              title="Sort by document date" aria-label="sort by document date"
                              style="width: 100%;text-align: left;">
                              <span class="c11n-link__content">
                                <span id="creationDateId"
                                  class="c11n-link__text account-header">{{tableSectionHeader.documentDateColumnLabel}}</span>
                                <svg viewBox="0 0 8 14" focusable="false" aria-hidden="true">
                                  <use xlink:href="#c11n-table-sort-arrow"></use>
                                  <use xlink:href="#c11n-table-sort-arrow"></use>
                                </svg>
                              </span>
                            </button>

                            <button type="button"
                              class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal accountresponsiveheader"
                              title="document date" aria-label="document date" style="width: 100%;text-align: left;">
                              <span class="c11n-link__content">
                                <a class="dropdown-toggle accountresponsiveheader" data-toggle="dropdown"
                                  c11nModalButtonTrigger (click)="chooseColumnModal.openModalDialog($event)">
                                  {{tableheading}}<b class="caret"></b>
                                </a>
                              </span>
                            </button>
                          </th>

                          <th scope="col" class="c11n-table__thead-th account-header"
                            [attr.aria-sort]="historicalDocumentTypeSortingState"
                            [ngClass]="[historicalDocumentTypeSortingState, historicalDocumentTypeSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']">
                            <button type="button" (click)="sortColumn('historicalDocumentType', 'string')"
                              [attr.aria-label]="'Sorted ' + historicalDocumentTypeSortingState"
                              class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                              title="Sort by document type" aria-label="sort by document type"
                              style="width: 100%;text-align: left;">
                              <span class="c11n-link__content">
                                <span id="historicalDocumentType"
                                  class="c11n-link__text type-header account-header">{{tableSectionHeader.documentTypeColumnLabel}}</span>
                                <svg viewBox="0 0 8 14" focusable="false" aria-hidden="true">
                                  <use xlink:href="#c11n-table-sort-arrow"></use>
                                  <use xlink:href="#c11n-table-sort-arrow"></use>
                                </svg>
                              </span>
                            </button>
                          </th>

                          <th scope="col" class="c11n-table__thead-th account-header"
                            [attr.aria-sort]="descriptionSortingState"
                            [ngClass]="[descriptionSortingState, descriptionSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']">
                            <button type="button" (click)="sortColumn('description', 'string')"
                              [attr.aria-label]="'Sorted ' + descriptionSortingState"
                              class="c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal"
                              title="Sort by description" aria-label="sort by description"
                              style="width: 100%;text-align: left;">
                              <span class="c11n-link__content account_align">
                                <span id="description"
                                  class="c11n-link__text account-header">{{tableSectionHeader.descriptionColumnLabel}}</span>
                                <svg viewBox="0 0 8 14" focusable="false" aria-hidden="true">
                                  <use xlink:href="#c11n-table-sort-arrow"></use>
                                  <use xlink:href="#c11n-table-sort-arrow"></use>
                                </svg>
                              </span>
                            </button>
                          </th>

                          <th scope="col"
                            class="c11n-table__thead-th c11n-table__thead-th--align-left responsivedownload">
                            <span id="view-download" class="view-download responsivedownload" title="view/download"
                              role="text" aria-label="view/download">
                              {{tableSectionHeader.viewDownloadColumnLabel}}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr *ngIf='shouldShowErrorBannerForTable'>
                          <td [colSpan]='5'>
                            <c11n-banner class="error-banner" [size]="'medium'" [hasCloseControl]="hasCloseControl"
                              [hasDetails]="hasDetails" [variant]="'error'" [headingText]="contentBody" [attr.aria-label]="contentBody" tabIndex="0">
                            </c11n-banner>
                          </td>
                        </tr> -->
                        <!--                        <tr *ngIf='shouldShowErrorBannerForApiFailure'>-->
                        <!--                          <td [colSpan]='5'>-->
                        <!--                            <c11n-banner class="error-banner" [size]="'medium'" [hasCloseControl]="hasCloseControl"-->
                        <!--                              [hasDetails]="hasDetails" [variant]="'error'" [headingText]="apiFailureErrorBody">-->
                        <!--                            </c11n-banner>-->
                        <!--                          </td>-->
                        <!--                        </tr>-->
                        <tr class="c11n-table__tr"
                          *ngFor="let historicalDocObj of historicalDocumentsList; let i = index">

                          <td class="recent-transaction-type c11n-table__td c11n-table__td--align-left">
                            {{historicalDocObj.requestDate}}</td>
                          <td class="recent-transaction-type c11n-table__td c11n-table__td--align-left account-header">
                            {{historicalDocObj.creationDate}}</td>
                          <td class="recent-transaction-type c11n-table__td c11n-table__td--align-left account-header">
                            {{historicalDocObj.historicalDocumentType}}</td>
                          <td class="recent-transaction-type c11n-table__td c11n-table__td--align-left account-header"
                            [innerHTML]="historicalDocObj.description">
                          </td>
                          <td
                            class="ps-5 recent-transaction-type c11n-table__td c11n-table__td--align-left responsivedownload account-header">
                            <c11n-icon _ngcontent-bii-c24 name="download" class="responsive_pdf_icon" tabIndex="0"
                              (click)='downloadpdf(historicalDocObj.id, $event, historicalDocObj.historicalDocumentType, historicalDocObj.creationDate)'
                              (keydown.space)='keyHandler($event, historicalDocObj.id, historicalDocObj.historicalDocumentType, historicalDocObj.creationDate);'
                              (keydown.enter)='keyHandler($event, historicalDocObj.id, historicalDocObj.historicalDocumentType, historicalDocObj.creationDate);'
                              title="Download document" [attr.id]="'download-icon-' + i" focusable="true"
                              attr.aria-label="Download a {{historicalDocObj.historicalDocumentType}} generated on {{historicalDocObj.creationDate  | date: 'longDate'}} with description {{historicalDocObj.description}}"
                              role="button">
                              <svg aria-hidden="true" class="c11n-download-small">
                                <use xlink:href="#download-small"></use>
                              </svg>
                            </c11n-icon>
                          </td>
                          <!--responsivedownload class is For Mobile view-->
                          <td *ngIf="showDocumentDate"
                            class="recent-transaction-type c11n-table__td c11n-table__td--align-left historical-header">
                            {{ historicalDocObj.creationDate }}
                          </td>
                          <td *ngIf="showDocumentType"
                            class="recent-transaction-type c11n-table__td c11n-table__td--align-left historical-header">
                            {{historicalDocObj.historicalDocumentType}}
                          </td>
                          <td *ngIf="showDescription"
                            class="recent-transaction-type c11n-table__td c11n-table__td--align-left historical-header"
                            [innerHTML]="historicalDocObj.description">
                          </td>
                          <td *ngIf="showDownload"
                            class="ps-5 recent-transaction-type c11n-table__td c11n-table__td--align-left responsivedownload historical-header">
                            <c11n-icon _ngcontent-bii-c24 name="download" class="responsive_pdf_icon" tabIndex="0"
                              (click)='downloadpdf(historicalDocObj.id, $event, historicalDocObj.historicalDocumentType, historicalDocObj.creationDate)'
                              (keydown.space)='keyHandler($event, historicalDocObj.id, historicalDocObj.historicalDocumentType, historicalDocObj.creationDate);'
                              (keydown.enter)='keyHandler($event, historicalDocObj.id, historicalDocObj.historicalDocumentType, historicalDocObj.creationDate);'
                              title="Download document" [attr.id]="'download-icon-' + i" focusable="true" role="button">
                              <svg aria-hidden="true" class="c11n-download-small">
                                <use xlink:href="#download-small"></use>
                              </svg>
                            </c11n-icon>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <c11n-modal-dialog-entrypoint class="hd-modal-entrypoint"></c11n-modal-dialog-entrypoint>
                    <table class="col-12">
                      <tbody>
                        <!-- <tr *ngIf='shouldShowErrorBannerForNoData'>
                          <td [colSpan]='12'>
                            <c11n-banner class="error-banner" [size]="'medium'" [hasCloseControl]="hasCloseControl"
                              [hasDetails]="hasDetails" [variant]="'error'" [headingText]="contentBody"
                              [attr.aria-label]="contentBody" title="Error option" tabIndex="0">
                            </c11n-banner>
                          </td>
                        </tr> -->
                        <tr *ngIf='shouldShowErrorBannerForTable'>
                          <td [colSpan]='5'>
                            <c11n-banner class="error-banner" [size]="'medium'" [hasCloseControl]="hasCloseControl"
                              [hasDetails]="hasDetails" [variant]="'error'" [headingText]="contentBody"
                              [attr.aria-label]="contentBody" tabIndex="0">
                            </c11n-banner>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class='bottom-paragraph-section mt-3 mb-4 fw-bold'
                  [innerHTML]='historicalDocumentsContent.generalFooterText'>
                </div>
                <div class='bottom-paragraph-section' [innerHTML]='historicalDocumentsContent.confirmationsDisclosure'>
                </div>
                <div class='bottom-paragraph-section' [innerHTML]='historicalDocumentsContent.statementsDisclosure'>
                </div>
                <div class='bottom-paragraph-section' [innerHTML]='historicalDocumentsContent.semiAnnualNotice'></div>

                <div class="disclaimer mb-4" [innerHTML]='historicalDocumentsContent.brokerageAssetsDisclosure'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ss-lib-page-layout>

<!-- For Mobile view-->
<c11n-modal-dialog #chooseColumnModal headingText="">
  <span c11n-modal-dialog-body>
    <ng-container>
      <div class="row">
        <div class="col-sm-10">
          <c11n-button buttonType="button" size="small" [variant]="showDocumentDate ? 'primary' : 'secondary'"
            class="d-inline-block update-button" [labelText]="tableSectionHeader.documentDateColumnLabel"
            (clickEvent)="toggleAccountdownload(tableSectionHeader.documentDateColumnLabel)"
            c11nModalButtonPrimary></c11n-button>
        </div>
        <div class="col-sm-10 my-1">
          <c11n-button buttonType="button" size="small" [variant]="showDocumentType ? 'primary' : 'secondary'"
            class="d-inline-block update-button" [labelText]="tableSectionHeader.documentTypeColumnLabel"
            (clickEvent)="toggleAccountdownload(tableSectionHeader.documentTypeColumnLabel)" c11nModalButtonPrimary>
          </c11n-button>
        </div>
        <div class="col-sm-10">
          <c11n-button buttonType="button" size="small" [variant]="showDescription ? 'primary' : 'secondary'"
            class="d-inline-block update-button" [labelText]="tableSectionHeader.descriptionColumnLabel"
            (clickEvent)="toggleAccountdownload(tableSectionHeader.descriptionColumnLabel)" c11nModalButtonPrimary>
          </c11n-button>
        </div>
        <div class="col-sm-10 mt-1">
          <c11n-button buttonType="button" size="small" [variant]="showDownload ? 'primary' : 'secondary'"
            class="d-inline-block update-button" [labelText]="tableSectionHeader.viewDownloadColumnLabel"
            (clickEvent)="toggleAccountdownload(tableSectionHeader.viewDownloadColumnLabel)" c11nModalButtonPrimary>
          </c11n-button>
        </div>
      </div>
    </ng-container>
  </span>
</c11n-modal-dialog>

<c11n-modal-dialog #errorModal [headingText]="errorPdfDownloadHeader" class="error-modal">
  <span c11n-modal-dialog-body>
    <ng-container>
      <div class="row">
        <span>{{contentBody}}</span>
        <c11n-button style="display: none;" c11nModalButtonPrimary></c11n-button>
      </div>
    </ng-container>
  </span>
</c11n-modal-dialog>