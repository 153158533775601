import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HistoricalDocumentsComponent} from "app/components/historical-documents/historical-documents.component";

const routes: Routes = [  {
  path: '',
  pathMatch: 'full',
  component: HistoricalDocumentsComponent,
  data: { pageId: 'historical-documents' },
},
  {
    path: '**',
    redirectTo: '',
  },];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
